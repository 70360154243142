.footer{
    display: flex;
    align-items: center;
    margin: -0.5rem -3.5rem;
    position:relative;
    margin-top: -2rem;
} 
.f-content{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 4rem;
    gap: 2rem;
    color: rgb(61, 22, 22);
    align-items: center;

}
.f-icons {
    display: flex;

    gap: 2rem;
}
@media screen and (max-width: 480px){
    .f-content{
      transform: scale(0.8);
    }
    .f-icons {
        transform: scale(0.7);
        margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 1024px){
    .f-content{
      transform: scale(0.5);
    }
    .f-icons {
        transform: scale(0.7);
        margin-bottom: 10px;
    }
  }