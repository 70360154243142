.floatingdiv{
    justify-content: space-around;
    display: flex;
    background:white;
    box-shadow:  10px 0 5px -2px #888;
    border-radius: 17px ;
    align-items: center;
    padding: 10px 30px 0px 0px;
    height: 4.5rem;
    width: 11rem;
    cursor: pointer;
}


.floatingdiv>img{
        transform: scale(0.4);
        cursor: pointer;
        
}
.floatingdiv>img:hover{
        transform: scale(0.5);
        transition: all 0.5s ease-in-out;
        
        
}


.floatingdiv>span{
    font-family: sans-serif;
    font-size: 16px;
    padding-right: 10px;

}
/* .floatingdiv>span:hover{
    color:red;
} */

.floatingdiv:hover{
    transform: scale(1.1);
    box-shadow: 10px 0 5px -2px #888;
    transition: all 0.5s ease-in-out;
}

