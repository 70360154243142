.intro{
    display: flex;
    height: 85vh;
    margin-top: 9rem;
}
.i-left{
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    gap: 2rem;
}

.i-name{
    display: flex;
    flex-direction: column;
}

.i-name>:nth-child(1){
    color: var(--black);
    font-weight: bold;
    font-size: 2rem;
}
.i-name>:nth-child(2){
    color:var(--orange);
    font-weight: bold;
    font-size:3rem;
    overflow: visible;
 
}
.i-name>:nth-child(3){
    gap: 0.7rem;
    font-weight: bold;
    margin-top: 0.5rem;
    font-size: 20px;
    color: black;
    

}
.i-name>:nth-child(4){
    font-weight: 100;
    font-size: 18px;
    margin-top: 1rem;
    color: var(--gray);

}

.i-button{
    text-decoration: none;
    width: 6rem;
    height: 2rem;
    z-index:1;
}
.i-icons{
    margin-top: 1rem;
    display: flex;
    gap:0rem;
    position: relative;
}
.i-icons>*{
    transform: scale(0.5);
}
.i-icons>*:hover{
    cursor: pointer;
}

.i-right{
 
    flex: 1;
    position: relative;
}
.i-right>*{
    position: absolute;
    z-index: 1;

}

.i-right>:nth-child(1){
    transform: scale(0.69) ;
    left: -15%;
    top:-9%
}

.i-right>:nth-child(2){
    top: -4.6rem;
    transform: scale(0.67);
    left: -3rem;
}


.i-right>:nth-child(3){
    transform: scale(0.9);
    top: -30%;
    left: 10%;


}

.i-right>:nth-child(4){
    transform:scale(0.5);
    top: 10%;
    left: -12%;
    border-radius: 19%;
    padding: 0;
    
}
.i-right>:nth-child(4):hover{
    transform: scale(0.7);
    transition: all 0.5s ease-in-out;
}

.right-floatingdiv{
    top: 20%;
    left: 60%;
}
.left-floatingdiv{
    top: 18rem;
    left: 2rem;
}


.blur{
    position: absolute;
    width: 22rem;
    height: 14rem;
    border-radius: 50%;
    background-color: #dabdec ;
    z-index: -9;
    top: -18%;
    left: 56%;
    filter: blur(40px);
  
  }
  @media screen and (max-width: 1024px) {
    .intro {
      flex-direction: column;
      gap: -10rem;
      height: 55rem;
    }
  
    .i-right {
      transform: scale(0.8);
      left: 5rem;
      top: -20px;
    }
    .i-right .blur {
      display:inline
    }
    .right-floatingdiv{
        top: 30%;
        left: 60%;
    }
  }
  @media screen and (max-width: 600px) {
    .intro {
        margin-top: 4rem;
      flex-direction: column;
      gap: -10rem;
      height: 55rem;
    }
  
    .i-right {
      transform: scale(0.7);
      left: -4rem;
      top: -20px;
    }
    .i-right>:nth-child(1){
        left: -5rem;
    }
    .i-right>:nth-child(2){
        left: -5rem;
    }
    .i-right .blur {
      display:inline
    }
    .i-icons{
        margin-top: 1rem;
    }

   
  }