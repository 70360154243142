.experience{
    color:var(--orange);
}

.experience__container{
    margin-top: 5px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}

.experience__container>div {
    background: linear-gradient(to right, #4880EC, #019CAD);
    padding: 2.4rem 5rem;
    border-radius:2rem;
    border: 1px solid transparent;
    transition: 0.6s ease-in-out;
}
.experience__container>div:hover{
    background: linear-gradient(#e66465, #9198e5) ;
    border-color: rgb(231, 144, 39);
    cursor: default;
    
}

.experience__container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: rgb(119, 13, 13);
    font-size: 2rem;

}

.experience__content{
    display: grid;
    grid-template-columns: 1fr  ;
    column-gap: 2rem;

}

.experience__details{
    display:flex;
    gap: 1rem;
    

}
.text-light{
    color: rgb(189, 148, 148);
    
}
.experience__details-icon{
    margin-top: 23px;
    color:#060607
}

@media screen and (max-width: 1024px) {
    .experience__container {
         grid-template-columns: 1fr;
     }
     .experience__container > div {
         width: 80%;
         padding: 2rem;
         margin: 0 auto;
     }
     .experience__content {
         padding: 1rem;
     }
    }
 
 
 @media screen and (max-width: 600px) {
         .experience__container {
             display: grid;
             justify-content: center;
             align-items: center;
             gap: 1rem;
             margin-right: 10px;
             
            
            
         }
         .experience__container > div {
             width: 100%;
             padding: 2rem 0.5rem;
         }
 }



