@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

*{
  font-family: 'Nunito', sans-serif;
}
:root
{
  --yellow: #F5C32C;
    --orange : #FCA61F;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --boxShadowRight: 60px 19px 0px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.App{
    padding: 0.5rem 3.5rem;
    overflow: hidden;
    color: var(--black);    
}
.button{
  background: linear-gradient(180deg, #fdc50f 26.71%, #fb982f 99.36%);
  box-shadow: 0px 20px 24px 3px rgba(251, 161, 40, 0.42);
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  z-index: 5;
  padding: 11px 26px 11px 26px;
}

.button:hover {
  background: white;
  cursor: pointer;
  color: var(--orange);
  border: 1px solid var(--orange);
}

