.toggle{
   display: flex;
   flex-direction: row;
    justify-content: center;
    border: 3px solid orange;
    border-radius: 1rem;
    width: 20%;
    height: 2%;
    margin-top: 14px;
    position: relative;
    padding: 2px;
  
}
.toggle>*{
    width: 1rem;
    height: 1rem;
    color: orange;
}
.t-button{
    border-radius: 100%;
    background: orange;
    position: absolute;
   
    align-items: center;
}
